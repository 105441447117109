.croatia {
  background-image: url("/src/assets/croatia.png");
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.region-container h1 {
  font-size: 3rem;
}

.region-container div h2 {
  color: #e30004;
}

.region-container div div {
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.croatia-mob {
  background-image: url("/src/assets/croatia.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1;
}

.region-container-mob h1 {
  font-size: 3rem;
  line-height: 3rem;
}

.region-container-mob div h2 {
  color: #e30004;
}

.region-container-mob div div {
  text-align: center;
}
