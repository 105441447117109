.square-button {
  text-decoration: none;
  height: 50px;
  background-color: #e30004;
  border: none;
  transition: all 0.2s ease-in-out;
  padding-right: 20px;
  border-radius: 5px;
  min-width: 200px;
}

.square-button:hover {
  transform: scale(1.1);
}

.square-button span {
  color: white;
  font-weight: bold;
}

.square-button .icon-container {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
