.circle-button {
  text-decoration: none;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background-color: #e30004;
  border: none;
  transition: all 0.2s ease-in-out;
}

.circle-button:hover {
  transform: scale(1.1);
}
