.contact-container {
  background-color: #e30004;
  width: 100vw;
  height: 50vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact-row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.contact-container h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 3rem;
}

.contact-container-mob {
  background-color: #e30004;
  width: 100vw;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact-row-mob {
  align-items: center;
  width: 100%;
}

.contact-container-mob h1 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}
