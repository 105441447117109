.what-container h1 {
  font-size: 3rem;
}

.what-container div h2 {
  color: #e30004;
}

.what-container div div {
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.what-container-mob h1 {
  font-size: 2rem;
  text-align: center;
}

.what-container-mob div h2 {
  color: #e30004;
}

.what-container-mob div div {
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  padding-bottom: 1rem;
}
