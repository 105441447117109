/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (min-width: 601px) {
  h1 {
    font-size: 30px;
  }

  .row-container {
    display: flex;
  }
}
