.container {
  background-color: #e30004;
}

.row-container {
  justify-content: space-evenly;
}

.col {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  text-align: center;
}

.col h2 {
  color: white;
}

.col span {
  color: white;
}

.container div h1 {
  color: white;
  font-size: 3rem;
}
