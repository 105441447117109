.landing-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/src/assets/car-image.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.landing-image .text {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text h1 {
  font-size: 5rem;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.text h2 {
  font-size: 3rem;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.button-container {
  display: flex;
}

.landing-image-mob {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/src/assets/car-image.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.landing-image-mob .text {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text-mob h1 {
  text-align: center;
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 3px black;
}

.text-mob h2 {
  font-size: 2rem;
  color: white;
  text-align: center;
  line-height: 2rem;
  text-shadow: 2px 2px 3px black;
}

.button-container-mob {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
